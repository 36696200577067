// import Button from "Components/Button/Button"

const NotFound = () => {
  return (
    <div>
      <div className="h-screen flex items-center justify-center">
        <h4 className="text-5xl font-medium">404 Not Found</h4>
        {/* <Button primary="true" xl="true" block="true" rounded="true">
          Retour à l'accueil
        </Button> */}
      </div>
    </div>
  )
  }

export default NotFound