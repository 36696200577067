import Accueil from "Pages/Accueil";

const App = () => {
  return (
    <div className="h-screen dark:bg-gray-900">
      <Accueil />
    </div>
  );
}

export default App;