import classNames from 'classnames';
import './Button.css';


const Button = ({ children, ...attributes }) => {

  const { primary, secondary, success, warning, danger, rounded, outline, xl, block } = attributes;
  const classes = classNames("px-3 py-1.5 border", {
    "text-xl": xl,
    "block": block,
    "border-blue-500 bg-blue-500 text-white": primary,
    "border-gray-900 bg-gray-900 text-white": secondary,
    "border-green-500 bg-green-500 text-white": success,
    "border-yellow-400 bg-yellow-400 text-white": warning,
    "border-red-500 bg-red-500 text-white": danger,
    "rounded-full": rounded,
    "bg-white": outline,
    "text-blue-500": outline && primary,
    "text-gray-900": outline && secondary,
    "text-green-500": outline && success,
    "text-yellow-500": outline && warning,
    "text-red-500": outline && danger,
  });

  return (
    <button type='button' className={classes} {...attributes}>
      {children}
    </button>
  )
}

Button.propTypes = {
  checkPassedProps: ( { primary, secondary, success, warning, danger } ) => {
    const count =
      Number(!!primary) +
      Number(!!secondary) +
      Number(!!success) +
      Number(!!warning) +
      Number(!!danger);
    if( count > 1 ){
      return new Error ('Error...');
    }
  }
}

export default Button