import App from 'App'
import Footer from 'Layouts/Footer/Footer'
import Header from 'Layouts/Header/Header'
import Contact from 'Pages/Contact'
import NotFound from 'Pages/NotFound'
import Portfolio from 'Pages/Portfolio'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

function AppRouter() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route exact path='/' element={ <App /> } />
        <Route path='/portfolio' element={ <Portfolio /> } />
        <Route path='/contact' element={ <Contact /> } />
        <Route path='*' element={ <NotFound /> } />
      </Routes>
      <Footer />
    </BrowserRouter>
  )
}

export default AppRouter