import useDarkMode from "Hook/useDarkMode"
import { SunIcon } from "@heroicons/react/24/outline";
import { MoonIcon } from "@heroicons/react/24/solid";
import Button from "Components/Button/Button";

const  DarkModeToggler = () => {
    const [ darkMode, setDarkMode ] = useDarkMode();

    const handleToggleDarkMode = () => {
        setDarkMode( !darkMode );
    }

    return (
        <Button
            onClick={ handleToggleDarkMode }
            className={ `btn_darkmode ${ darkMode ? 'btn_darkmode_off' : 'btn_darkmode_on' }` }
        >
            { darkMode ? <SunIcon className="h-5 w-5" />
                : <MoonIcon className="h-5 w-5" /> }
        </Button>
    )
}

export default DarkModeToggler;