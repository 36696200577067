const Accueil = () => {
  return (
    <div>
      <h4>Accueil</h4>
      <h4>Preview portfolio</h4>
      <h4>Contact</h4>
    </div>
  )
}

export default Accueil