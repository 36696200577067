import { useEffect, useState } from "react";

const useDarkMode = () => {
    const [ darkMode, setDarkMode ] = useState(true);

    useEffect( () => {
        // switch class on root element of DOM
        const root = window.document.documentElement;
        root.classList.toggle('dark', darkMode);

        // save darkMode to local storage
        localStorage.setItem('darkMode', darkMode);
    }, [darkMode]);

    return [ darkMode, setDarkMode ];
}

export default useDarkMode;